import { RichTextField, Slice } from '@prismicio/types';
import cn from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { TooltipWithPopper } from '@ee-monorepo/prismic/shared/ui';
import { ImageWrapper, SliceWrapper } from '@ee-monorepo/prismic/shared/ui';
import { storySerializer } from '@ee-monorepo/prismic/shared/serializers';
import { getColor } from '@ee-monorepo/shared/utilities/functions';

interface LeaseAndLoanCards extends Slice {
  variation?: 'default';
  primary: {
    title?: PrismicFields.RichTextField;
    show_header_disclaimer?: boolean;
    header_disclaimer_text?: PrismicFields.RichTextField;
    body?: PrismicFields.RichTextField;
    show_body_disclaimer?: boolean;
    body_disclaimer_text?: PrismicFields.RichTextField;
  };
  items: {
    card_image: PrismicFields.Image;
    card_description: PrismicFields.RichTextField;
    card_background_color: PrismicFields.Select;
  }[];
}

export interface LeaseAndLoanCardsProps extends PrismicFields.SliceBase {
  slice: LeaseAndLoanCards;
}

export function LeaseAndLoanCards(props: LeaseAndLoanCardsProps) {
  const {
    title,
    show_header_disclaimer,
    header_disclaimer_text,
    body,
    show_body_disclaimer,
    body_disclaimer_text,
  } = props.slice.primary;
  const { items } = props.slice;

  return (
    <SliceWrapper
      data-testid="lease-and-loan-cards-slice"
      data-slice-index={props.index}
      className="snap-container"
    >
      <div
        data-testid="lease-and-loan-cards-container"
        className="snap-row flex-col sm:flex-row"
      >
        <div
          data-testid="content-section"
          className={cn('w-full lg:px-4 md:px-3 sm:px-4 text-center')}
        >
          <div className="md:w-3/5 lg:w-3/5 mx-auto" data-testid="header">
            <div
              className="headingL md:headingXL text-SnapDarkBlue"
              data-testid="slice-title"
            >
              {show_header_disclaimer &&
              header_disclaimer_text &&
              header_disclaimer_text.length > 0 &&
              title &&
              title.length > 0 &&
              title[0]?.type ? (
                <TooltipWithPopper
                  mainContent={title as RichTextField}
                  mainContentSerializer={storySerializer}
                  popperContent={header_disclaimer_text as RichTextField}
                  elementType={title[0]?.type}
                  mainContentColor="text-SnapDarkBlue"
                />
              ) : (
                <div
                  data-testid="title-without-popper"
                  className="headingL md:headingXL text-SnapDarkBlue"
                >
                  {title && title.length > 0 ? (
                    <PrismicRichText
                      field={title}
                      components={storySerializer}
                    />
                  ) : null}
                </div>
              )}
            </div>
            <div
              className="bodyFont md:headingM text-SnapGrey500"
              data-testid="sub-title"
            >
              {show_body_disclaimer &&
              body &&
              body.length > 0 &&
              body_disclaimer_text &&
              body_disclaimer_text.length > 0 &&
              body[0]?.type ? (
                <TooltipWithPopper
                  mainContent={body as RichTextField}
                  mainContentSerializer={storySerializer}
                  popperContent={body_disclaimer_text as RichTextField}
                  elementType={body[0]?.type}
                />
              ) : body ? (
                <div
                  className="bodyFont md:headingM text-SnapGrey500"
                  data-testid="sub-title"
                >
                  {body && body.length > 0 ? (
                    <PrismicRichText
                      field={body}
                      components={storySerializer}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>

          <div
            data-testid="cardsContainer"
            className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 justify-between"
          >
            {items &&
              items?.length > 0 &&
              items?.map((item, index) => {
                const { card_image, card_description, card_background_color } =
                  item;
                return card_description && card_description?.length > 0 ? (
                  <div
                    key={index}
                    data-testid="repeatable-card"
                    className={cn(
                      card_background_color
                        ? getColor(card_background_color).backgroundColorClass
                        : '',
                      'mt-5 p-8 text-center rounded-[16px]'
                    )}
                  >
                    {card_image ? (
                      <div className="image-container text-center mb-4">
                        <ImageWrapper
                          className="d-block m-auto"
                          src={card_image?.url ?? ''}
                          alt={card_image?.alt ?? ''}
                          width={card_image?.dimensions?.width}
                          height={card_image?.dimensions?.height}
                        />
                      </div>
                    ) : null}
                    {item?.card_description ? (
                      <div>
                        <PrismicRichText
                          field={item?.card_description}
                          components={storySerializer}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null;
              })}
          </div>
        </div>
      </div>
    </SliceWrapper>
  );
}

export default LeaseAndLoanCards;
